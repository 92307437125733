<template>
  <l-map :zoom="zoom" :center="center" :options="mapOptions" @update:center="centerChanged">
    <slot name="control"></slot>
    <slot name="tile-server">
      <l-tile-layer :url="tileUrl" :zIndex="1" />
    </slot>
    <slot name="markers">
      <l-marker v-for="(marker, idx) in markers" :key="idx" :lat-lng="marker.latLng">
        <l-popup>{{ marker.popup }}</l-popup>
      </l-marker>
    </slot>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

export default {
  name: 'MapView',
  props: {
    center: Array,
    zoom: {
      type: Number,
      default: 8,
    },
    zoomControl: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    scrollWheelZoom: {
      type: Boolean,
      default: false,
    },
    tileUrl: {
      type: String,
      default: 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=5DGJMofNtAj9Buy0YJ5S',
    },
    markers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mapOptions() {
      return {
        zoomControl: this.zoomControl,
        dragging: this.dragging,
        scrollWheelZoom: this.scrollWheelZoom,
        attributionControl: false,
      };
    },
  },
  methods: {
    centerChanged(e) {
      this.$emit('center-changed', e);
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
};
</script>
